export const inhidereLunaPrecedentaTableColumns = [
  {
    accessor: "actions",
    Header: "Actiuni",
    width: "10%",
  },
  {
    accessor: "dataInchiderii",
    Header: "Data inchiderii",
  },
  {
    accessor: "pentruPerioada",
    Header: "Pentru perioada",
  },
  {
    accessor: "aprobatDe",
    Header: "Aprobat de",
  },
  {
    accessor: "totalNoteContabile",
    Header: "Total note contabile",
  },
];

export const noteContabileDeAprobatTableColumns = [
  {
    accessor: "dataInchiderii",
    Header: "DATA ÎNCHIDERII LUINII",
  },
  {
    accessor: "perioadaFisier",
    Header: "PERIOADA fisier",
  },
  {
    accessor: "numeFisier",
    Header: "Nume Fisier",
  },
  {
    accessor: "Generat La",
    Header: "Generat La",
  },
  {
    Header: "Actiuni",
    accessor: "actions",
    width: "10%",
    disableSortBy: true,
  },
];

export interface IMonthClosing {
  actuarialApproval: boolean;
  claimApproval: boolean;
  approvals: { name: string; type: string }[];
  documents: { type: string; url: string }[];
  documentsCount: number;
  closingDate: string;
  createdAt: string;
  deletedAt: string | null;
  id: string;
  masterApproval: boolean;
  underwritingApproval: boolean;
  updatedAt: string;
  isProcessed: boolean;
}

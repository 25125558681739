import MDButton from "../../components/mui/MDButton";
import { ArrowBack, Check } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { Card, Grid } from "@mui/material";
import MDBox from "../../components/mui/MDBox";
import MDTypography from "../../components/mui/MDTypography";
import MDAlert from "../../components/mui/MDAlert";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { IMonthClosing } from "../../types/inchidereLunaTypes";
import { useNavigate, useParams } from "react-router-dom";
import { Endpoints, getRequest } from "../../helpers/api";
import { toast } from "react-toastify";
import { formatDateWithLocale } from "../../utils";
import { EApprovalType } from "@DCraig-Asig/eazy-resources/dist/enums/accounting/approval-type.enum";

const InchidereLunaDetails = () => {
  const [monthClosing, setMonthClosing] = useState<IMonthClosing>();
  const [lastMonthClosing, setLastMonthClosing] = useState<IMonthClosing>();

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: prevMonthClosings } = await getRequest(
          `${Endpoints.ACCOUNTING_MANAGEMENT.GET_PREVIOUS}?approvalType=${EApprovalType.MASTER}`
        ).catch((e) => {
          console.log(e);
        });

        const monthClosing = prevMonthClosings.find(
          (pmc: IMonthClosing) => pmc.id === params.id
        );

        const lastMonthClosing =
          prevMonthClosings.length > 1 &&
          prevMonthClosings.sort((a: IMonthClosing, b: IMonthClosing) => {
            if (new Date(a.closingDate) > new Date(b.closingDate)) {
              return -1;
            } else if (new Date(a.closingDate) < new Date(b.closingDate)) {
              return 1;
            }

            return 0;
          })[1];

        if (lastMonthClosing) {
          setLastMonthClosing(lastMonthClosing);
        }

        if (!monthClosing) {
          toast.error("Inchiderea de luna nu a fost gasita");
          navigate("/inchidereluna");
          return;
        }

        setMonthClosing(monthClosing);
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDButton
        variant="text"
        color="error"
        size="small"
        style={{ marginTop: 16 }}
        startIcon={<ArrowBack />}
        onClick={() => {
          navigate("/inchidereluna");
        }}
      >
        Înapoi la pagina principală
      </MDButton>
      {monthClosing && (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container pt={2} pb={2} spacing={2}>
              <Grid item xs={3}>
                <Card>
                  <MDBox p={3} display="flex" flexDirection="column" gap={0.5}>
                    <MDTypography
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#7B809A",
                      }}
                    >
                      Data închiderii de lună
                    </MDTypography>
                    <MDTypography
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: "#344767",
                      }}
                    >
                      {formatDateWithLocale(monthClosing?.closingDate)}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <MDBox p={3} display="flex" flexDirection="column" gap={0.5}>
                    <MDTypography
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#7B809A",
                      }}
                    >
                      Pentru perioada
                    </MDTypography>
                    <MDTypography
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: "#344767",
                      }}
                    >
                      {!!lastMonthClosing &&
                        `${formatDateWithLocale(
                          lastMonthClosing?.closingDate
                        )} - `}
                      {formatDateWithLocale(monthClosing?.closingDate)}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <MDBox p={3} display="flex" flexDirection="column" gap={0.5}>
                    <MDTypography
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#7B809A",
                      }}
                    >
                      Total note contabile
                    </MDTypography>
                    <MDTypography
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: "#344767",
                      }}
                    >
                      {monthClosing?.documentsCount}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <MDBox p={3} display="flex" flexDirection="column" gap={0.5}>
                    <MDTypography
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#7B809A",
                      }}
                    >
                      Închidere aprobată de
                    </MDTypography>
                    <MDTypography
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: "#344767",
                      }}
                    >
                      {monthClosing?.approvals?.find((a) => a.type === "MASTER")
                        ?.name || "-"}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <MDAlert color="success">
            <MDBox display="flex" gap={1} alignItems="center">
              <Check style={{ height: 16, color: "white" }} />
              <MDTypography
                style={{ fontSize: 16, fontWeight: 700, color: "white" }}
              >
                Închiderea de lună a fost efectuată cu succes
              </MDTypography>
            </MDBox>
          </MDAlert>
          {/*<Box sx={{ flexGrow: 1 }}>*/}
          {/*  <Grid container pt={2} pb={2} spacing={2}>*/}
          {/*    {data?.map((d, index) => (*/}
          {/*      <Grid item xs={4} key={index}>*/}
          {/*        <Card>*/}
          {/*          <MDBox p={3} display="flex" flexDirection="column" gap={2}>*/}
          {/*            <MDBox*/}
          {/*              display="flex"*/}
          {/*              justifyContent="space-between"*/}
          {/*              alignItems="center"*/}
          {/*            >*/}
          {/*              <MDBox display="flex" flexDirection="column">*/}
          {/*                <MDTypography*/}
          {/*                  style={{*/}
          {/*                    color: "#344767",*/}
          {/*                    fontSize: 16,*/}
          {/*                    fontWeight: 700,*/}
          {/*                  }}*/}
          {/*                >*/}
          {/*                  {d.title}*/}
          {/*                </MDTypography>*/}
          {/*                <MDTypography*/}
          {/*                  style={{*/}
          {/*                    fontSize: 16,*/}
          {/*                    fontWeight: 400,*/}
          {/*                    color: "#7B809A",*/}
          {/*                  }}*/}
          {/*                >*/}
          {/*                  {d.noteContabile.length} note contabile*/}
          {/*                </MDTypography>*/}
          {/*              </MDBox>*/}
          {/*              <MDButton*/}
          {/*                size="small"*/}
          {/*                variant="gradient"*/}
          {/*                color="light"*/}
          {/*                onClick={() => {*/}
          {/*                  getRequest(*/}
          {/*                    `${Endpoints.ACCOUNTING_MANAGEMENT.DOWNLOAD_ZIP_BY_TYPE}?approvalType=${EApprovalType.UNDERWRITING}`*/}
          {/*                  );*/}
          {/*                }}*/}
          {/*              >*/}
          {/*                DESCARCĂ TOATE*/}
          {/*              </MDButton>*/}
          {/*            </MDBox>*/}
          {/*            <MDBox>*/}
          {/*              <MDTypography style={{ fontSize: 12 }}>*/}
          {/*                Aprobat de: <b>{d.approvedBy}</b>*/}
          {/*              </MDTypography>*/}
          {/*            </MDBox>*/}
          {/*            <MDBox gap={2} display="flex" flexDirection="column">*/}
          {/*              {d.noteContabile.map(*/}
          {/*                (*/}
          {/*                  n: { title: string; type: string },*/}
          {/*                  index: number*/}
          {/*                ) => (*/}
          {/*                  <MDBox*/}
          {/*                    key={index}*/}
          {/*                    display="flex"*/}
          {/*                    justifyContent="space-between"*/}
          {/*                    alignItems="center"*/}
          {/*                  >*/}
          {/*                    <MDBox>*/}
          {/*                      <MDTypography*/}
          {/*                        style={{ fontSize: 14, color: "#344767" }}*/}
          {/*                      >*/}
          {/*                        {n.title}*/}
          {/*                      </MDTypography>*/}
          {/*                      <MDTypography*/}
          {/*                        style={{ color: "#7B809A", fontSize: 12 }}*/}
          {/*                      >*/}
          {/*                        {n.type}*/}
          {/*                      </MDTypography>*/}
          {/*                    </MDBox>*/}
          {/*                    <MDButton*/}
          {/*                      size="small"*/}
          {/*                      variant="text"*/}
          {/*                      color="info"*/}
          {/*                      startIcon={<FileDownload />}*/}
          {/*                      width="100%"*/}
          {/*                    >*/}
          {/*                      Descarcă*/}
          {/*                    </MDButton>*/}
          {/*                  </MDBox>*/}
          {/*                )*/}
          {/*              )}*/}
          {/*            </MDBox>*/}
          {/*          </MDBox>*/}
          {/*        </Card>*/}
          {/*      </Grid>*/}
          {/*    ))}*/}
          {/*  </Grid>*/}
          {/*</Box>*/}
        </>
      )}
    </DashboardLayout>
  );
};

export default InchidereLunaDetails;
